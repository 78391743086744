const blockDefaultContentScrollReveal = () => {
  // Block default on all pages except frontpage
  // Text on the left- and image on the right block
  ScrollReveal().reveal(".block-default-content .list-separated .content-side .image-wrapper", { delay: 600, duration: 1000, scale: 0.8 }); // eslint-disable-line
  ScrollReveal().reveal(".block-default-content .list-separated .content-side .title-content", { delay: 600, duration: 2000,  distance: "20px", origin: "left"}); // eslint-disable-line
  ScrollReveal().reveal(".block-default-content .list-separated .content-side .logo-content-wrapper", { delay: 600, duration: 2000,  distance: "40px", origin: "righ"}); // eslint-disable-line

  // Image on top and content under
  ScrollReveal().reveal(".block-default-content .list-separated .content-under .image-wrapper", { delay: 600, duration: 1000, distance: "5%" }); // eslint-disable-line
  ScrollReveal().reveal(".block-default-content .list-separated .content-under .title-content", { delay: 600, duration: 1000, scale: 0.8 }); // eslint-disable-line
  ScrollReveal().reveal(".block-default-content .list-separated .content-under .logo-content-wrapper", { delay: 800, duration: 2000, scale: 0.9 }); // eslint-disable-line

  // Block default on front page
  // First block
  ScrollReveal().reveal(".block-default-content .list-grouped .single-list:first-child .image-wrapper", { delay: 300, duration: 1000, scale: 0.9 }); // eslint-disable-line
  ScrollReveal().reveal(".block-default-content .list-grouped .single-list:first-child .h2-wrapper", { delay: 0, distance: "10px", origin: "left" }); // eslint-disable-line
  ScrollReveal().reveal(".block-default-content .list-grouped .single-list:first-child .text-content-wrapper", { delay: 1200, scale: 0.9, duration: 1300 }); // eslint-disable-line
  // Second block
  ScrollReveal().reveal(".block-default-content .list-grouped .single-list:nth-child(2) .image-wrapper", { delay: 300, duration: 1000, scale: 0.9 }); // eslint-disable-line
  ScrollReveal().reveal(".block-default-content .list-grouped .single-list:nth-child(2) .h2-wrapper", { delay: 0, distance: "10px", origin: "left" }); // eslint-disable-line
  ScrollReveal().reveal(".block-default-content .list-grouped .single-list:nth-child(2) .text-content-wrapper", { delay: 600, scale: 0.9, duration: 1300 }); // eslint-disable-line
  // Last block
  ScrollReveal().reveal(".block-default-content .list-grouped .single-list:last-child .image-wrapper", { delay: 600, duration: 1300, scale: 0.9 }); // eslint-disable-line
  ScrollReveal().reveal(".block-default-content .list-grouped .single-list:last-child .h2-wrapper", { delay: 600, duration: 1300, distance: "20px", origin: "left" }); // eslint-disable-line
  ScrollReveal().reveal(".block-default-content .list-grouped .single-list:last-child .text-content-wrapper", { delay: 600, scale: 0.9, duration: 1300 }); // eslint-disable-line

};

document.addEventListener("DOMContentLoaded", () => {
  blockDefaultContentScrollReveal();
});
